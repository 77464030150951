<template>
  <div>
    <Header :seet="number" />
      <el-row type="flex" class="account—settings">
        <el-col :span="3" style="width:15.3%;"></el-col>
        <el-col :span="17">
          <div class="settings_box">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>账号中心</el-breadcrumb-item>
              <el-breadcrumb-item @click.native="goto()">账户设置</el-breadcrumb-item>
              <el-breadcrumb-item>密码修改</el-breadcrumb-item>
            </el-breadcrumb>
            <div style="display:flex;">
              <div class="box_left">
                <LeftHurdle/>
              </div>
              <div class="box_right">
                <div class="box_right_top">密码修改</div>
                <div class="box_right_cent">
                    <div class="cent_pass flex-center">
                        <div class=" cent_pass_box">
                            <div class="flex-start pass_box">
                                <div class="original_text">原密码:</div>
                                <el-input></el-input>
                            </div>
                            <div class="flex-start pass_box">
                                <div class="original_text">新密码:</div>
                                <el-input></el-input>
                            </div>
                            <div class="flex-start pass_box">
                                <div class="origin_text">再次输入密码:</div>
                                <el-input></el-input>
                            </div>
                            <div class="pass_box flex-center">
                                <div class="pass_btn">确认修改</div>
                            </div>
                        </div>
                        
                    </div>
                  
                </div>
              </div>
            </div>
            
          </div>
        </el-col>
        <el-col :span="3" style="width:15.5%;"></el-col>
    </el-row>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
export default {
  components: { Header,Footer,LeftHurdle },
  data () {
      return{
        number:'4',
      }
  },
  methods:{
    changePassword(){
      this.$router.push({ name: 'ChangePassword' })
    },
    goto(){
      this.$router.push({ name: 'AccountSettings'})
    }
  }

}
</script>

<style lang="scss">
.settings_box{
  margin: 30px 0;
  .el-breadcrumb{
    font-size: 18px;
    padding-bottom: 20px;
  }
  .box_left{
    margin-right: 20px;
  }
  .box_right{
    width: 87%;
    height: 660px;
    border: 1px solid #d7d7d7;
    border-radius: 10px;
    .box_right_top{
      height: 40px;
      line-height: 40px;
      padding-left: 40px;
      background: #d7d7d7;
      border-bottom: 1px solid #d7d7d7;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      font-weight: bold;
    }
    .cent_pass{
        width: 100%;
        margin-top: 155px;
    }
    .cent_pass_box{
        width: 37%;
        font-size: 16px;
        color: #666666;
    }
    .pass_box{
        margin-bottom: 30px;
    }
    .original_text{
        width: 100px;
        margin-left: 55px;

    }
    .origin_text{
        width: 180px;
    }
    .pass_btn{
        height: 40px;
        line-height: 40px;
        width: 247px;
        color: #fff;
        font-size: 14px;
        background: #CD9F49;
        text-align: center;
        border-radius: 10px;
    }
  }
}
</style>